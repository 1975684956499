import React from 'react';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h1>Hi.</h1>
      <p>
        We're making lots of improvements and will be back soon.
      </p>
    </div>
  </section>


export default Introduction;
